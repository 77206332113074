import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// POST : 이메일 중복조회 및 인증메일 전송 (client)
export const postKtcDuplicateEmailCheck = async (data) => {
  // const data = {
  //     email,
  //     lang : "ko" | "en" | "zh-Hans" | "zh-Hant"
  // }

  return await axios
    .post('/apiv1/ktc/duplicateEmailCheck', data)
    .then((res) => res.data)
    .catch((err) => console.err(err))
}

// POST : 인증번호 확인 (Client)
export const postKtcCertNumberCheck = async (data) => {
  // const data = {
  //   email,
  //   cert_number
  // }

  return await axios
    .post('/apiv1/ktc/certNumberCheck', data)
    .then((res) => res.data)
    .catch((err) => console.err(err))
}

// POST : 국가코드 리스트 (client)
export const postKtcKtcCountryCodeList = async (lang) => {
  return await axios
    .post('/apiv1/ktc/ktcCountryCodeList', { lang })
    .then((res) => res.data.data)
    .catch((err) => console.error(err))
}

// POST : 회원가입 (Client)
export const postKcardSignup = async (data) => {
  // const data = {
  //   email,
  //   password,
  //   name,
  //   ntcd,
  //   svc_id,
  //   bdt,
  //   sx_dscd,
  //   lang
  // }

  return await axios
    .post('/apiv1/kcard/signup', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// POST : RWG 상담정보 저장 (Client)
export const postRwgInfo = async (data) => {
  // const data = {
  //   date,
  //   time,
  //   people,
  //   name,
  //   email,
  //   type,
  //   store_id,
  //   google_lang, // 브라우저 언어
  //   option_lang // 사용자 언어
  // }

  return await axios
    .post('/apiv1/rwg/info', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// GET : 상담 채팅 - 채팅내용 불러오기
export const getCnsltId = async (order_id, env = 'server') => {
  return await axios
    .get(`${env === 'server' ? host : '/apiv1'}/cnslt/${order_id}`, {
      params: { order_id },
    })
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// POST : 상담 채팅 - 메세지 입력 (Client)
export const postCnsltTranslateInsert = async (data) => {
  // const data = {
  //   web_lang,
  //   redtable_lang,
  //   contents,
  //   consultation_id
  // }

  return await axios
    .post('/apiv1/cnslt/translate/insert', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// POST : 상담 채팅 - 언어 변환 (Client)
export const postCnsltTranslateChange = async (data) => {
  // const data = {
  //   redtable_lang,
  //   consultation_id
  // }

  return await axios
    .post('/apiv1/cnslt/translate/change', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// GET : 견적서 조회 (Client)
export const getCnsltEstimateId = async (estimates_id, env = 'client') => {
  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/cnslt/estimate/${estimates_id}`)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// PUT : 견적서&계약서 동의 (Client)
export const putRwgIsagree = async (id) => {
  return await axios
    .put('/apiv1/rwg/isagree', { id })
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// POST : KTC 로그인 (Client)
export const postKtcLogin = async (data) => {
  // const data = {
  //   svc_id,
  //   password
  // }

  return await axios
    .post('/apiv1/ktc/login', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// put : RWG 로그인 후 유저ID 바꾸기 (Client)
export const putRwgChangeLogin = async (data) => {
  // const data = {
  //   pre_id,
  //   login_id
  // }

  return await axios
    .put('/apiv1/rwg/change-login', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

// POST : 바우처 조회 (Server)
export const postKtcWallet = async (token) => {
  const headers = { Authorization: `Bearer ${token}` }
  const data = {
    lang: 'ko',
    type: 1,
  }

  return axios
    .post(`${host}/ktc/wallet`, data, { headers })
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

export const postGooglecomMapsConversionCollect = async () => {
  const data = {
    conversion_partner_id: process.env.NEXT_PUBLIC_RWG_CONVERSION_PARTNER_ID,
    rwg_token: process.env.NEXT_PUBLIC_RWG_TOKEN,
    merchant_changed: 2,
  }

  return axios
    .post('/google-conversion', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}
