export const setRwgLocalstorage = (store_name, rwg_rending_type) => {
  // 예약 정보 삭제
  localStorage.removeItem('rwg_information')

  // 상점 이름 새로 저장
  localStorage.setItem('rwg_store_name', store_name)

  // RWG 타입 새로 저장
  localStorage.setItem('rwg_render_type', rwg_rending_type)
}
